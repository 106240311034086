export const AboutFeaturesSection = () => import('../../components/sections/AboutFeaturesSection.vue' /* webpackChunkName: "components/about-features-section" */).then(c => wrapFunctional(c.default || c))
export const AboutHeroSection = () => import('../../components/sections/AboutHeroSection.vue' /* webpackChunkName: "components/about-hero-section" */).then(c => wrapFunctional(c.default || c))
export const BoxSection = () => import('../../components/sections/BoxSection.vue' /* webpackChunkName: "components/box-section" */).then(c => wrapFunctional(c.default || c))
export const ContactUsFormSection = () => import('../../components/sections/ContactUsFormSection.vue' /* webpackChunkName: "components/contact-us-form-section" */).then(c => wrapFunctional(c.default || c))
export const FeatureCardSection = () => import('../../components/sections/FeatureCardSection.vue' /* webpackChunkName: "components/feature-card-section" */).then(c => wrapFunctional(c.default || c))
export const FeatureSectionBanner = () => import('../../components/sections/FeatureSectionBanner.vue' /* webpackChunkName: "components/feature-section-banner" */).then(c => wrapFunctional(c.default || c))
export const FeaturesInCheckeredOrderSection = () => import('../../components/sections/FeaturesInCheckeredOrderSection.vue' /* webpackChunkName: "components/features-in-checkered-order-section" */).then(c => wrapFunctional(c.default || c))
export const FeaturesWithDescriptionSection = () => import('../../components/sections/FeaturesWithDescriptionSection.vue' /* webpackChunkName: "components/features-with-description-section" */).then(c => wrapFunctional(c.default || c))
export const HelpAnyQuestion = () => import('../../components/sections/HelpAnyQuestion.vue' /* webpackChunkName: "components/help-any-question" */).then(c => wrapFunctional(c.default || c))
export const HelpDeskSection = () => import('../../components/sections/HelpDeskSection.vue' /* webpackChunkName: "components/help-desk-section" */).then(c => wrapFunctional(c.default || c))
export const HelpFAQ = () => import('../../components/sections/HelpFAQ.vue' /* webpackChunkName: "components/help-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const HelpSubmitSection = () => import('../../components/sections/HelpSubmitSection.vue' /* webpackChunkName: "components/help-submit-section" */).then(c => wrapFunctional(c.default || c))
export const HeroSection = () => import('../../components/sections/HeroSection.vue' /* webpackChunkName: "components/hero-section" */).then(c => wrapFunctional(c.default || c))
export const ItemsInBorderedSection = () => import('../../components/sections/ItemsInBorderedSection.vue' /* webpackChunkName: "components/items-in-bordered-section" */).then(c => wrapFunctional(c.default || c))
export const LeftImageWithDescriptionSection = () => import('../../components/sections/LeftImageWithDescriptionSection.vue' /* webpackChunkName: "components/left-image-with-description-section" */).then(c => wrapFunctional(c.default || c))
export const MainAbout = () => import('../../components/sections/MainAbout.vue' /* webpackChunkName: "components/main-about" */).then(c => wrapFunctional(c.default || c))
export const MainAllProductsSection = () => import('../../components/sections/MainAllProductsSection.vue' /* webpackChunkName: "components/main-all-products-section" */).then(c => wrapFunctional(c.default || c))
export const MainContactSection = () => import('../../components/sections/MainContactSection.vue' /* webpackChunkName: "components/main-contact-section" */).then(c => wrapFunctional(c.default || c))
export const MainHeroSection = () => import('../../components/sections/MainHeroSection.vue' /* webpackChunkName: "components/main-hero-section" */).then(c => wrapFunctional(c.default || c))
export const MainHeroSliderSection = () => import('../../components/sections/MainHeroSliderSection.vue' /* webpackChunkName: "components/main-hero-slider-section" */).then(c => wrapFunctional(c.default || c))
export const MainProductsSection = () => import('../../components/sections/MainProductsSection.vue' /* webpackChunkName: "components/main-products-section" */).then(c => wrapFunctional(c.default || c))
export const MarqueeSection = () => import('../../components/sections/MarqueeSection.vue' /* webpackChunkName: "components/marquee-section" */).then(c => wrapFunctional(c.default || c))
export const ParticularsSection = () => import('../../components/sections/ParticularsSection.vue' /* webpackChunkName: "components/particulars-section" */).then(c => wrapFunctional(c.default || c))
export const PartnersMainSection = () => import('../../components/sections/PartnersMainSection.vue' /* webpackChunkName: "components/partners-main-section" */).then(c => wrapFunctional(c.default || c))
export const PdfCatalogSection = () => import('../../components/sections/PdfCatalogSection.vue' /* webpackChunkName: "components/pdf-catalog-section" */).then(c => wrapFunctional(c.default || c))
export const ProductAboutSection = () => import('../../components/sections/ProductAboutSection.vue' /* webpackChunkName: "components/product-about-section" */).then(c => wrapFunctional(c.default || c))
export const ProductBenefitsSection = () => import('../../components/sections/ProductBenefitsSection.vue' /* webpackChunkName: "components/product-benefits-section" */).then(c => wrapFunctional(c.default || c))
export const ProductBoxSection = () => import('../../components/sections/ProductBoxSection.vue' /* webpackChunkName: "components/product-box-section" */).then(c => wrapFunctional(c.default || c))
export const ProductCardsSection = () => import('../../components/sections/ProductCardsSection.vue' /* webpackChunkName: "components/product-cards-section" */).then(c => wrapFunctional(c.default || c))
export const ProductFeatureSection = () => import('../../components/sections/ProductFeatureSection.vue' /* webpackChunkName: "components/product-feature-section" */).then(c => wrapFunctional(c.default || c))
export const ProductVideoSection = () => import('../../components/sections/ProductVideoSection.vue' /* webpackChunkName: "components/product-video-section" */).then(c => wrapFunctional(c.default || c))
export const TermsMainSection = () => import('../../components/sections/TermsMainSection.vue' /* webpackChunkName: "components/terms-main-section" */).then(c => wrapFunctional(c.default || c))
export const VideoSection = () => import('../../components/sections/VideoSection.vue' /* webpackChunkName: "components/video-section" */).then(c => wrapFunctional(c.default || c))
export const UIAccordion = () => import('../../components/ui/UIAccordion.vue' /* webpackChunkName: "components/u-i-accordion" */).then(c => wrapFunctional(c.default || c))
export const UIAccordionItem = () => import('../../components/ui/UIAccordionItem.vue' /* webpackChunkName: "components/u-i-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const UIBulletedList = () => import('../../components/ui/UIBulletedList.vue' /* webpackChunkName: "components/u-i-bulleted-list" */).then(c => wrapFunctional(c.default || c))
export const UIButton = () => import('../../components/ui/UIButton.vue' /* webpackChunkName: "components/u-i-button" */).then(c => wrapFunctional(c.default || c))
export const UICard = () => import('../../components/ui/UICard.vue' /* webpackChunkName: "components/u-i-card" */).then(c => wrapFunctional(c.default || c))
export const UICardBordered = () => import('../../components/ui/UICardBordered.vue' /* webpackChunkName: "components/u-i-card-bordered" */).then(c => wrapFunctional(c.default || c))
export const UICheckbox = () => import('../../components/ui/UICheckbox.vue' /* webpackChunkName: "components/u-i-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UICheckboxList = () => import('../../components/ui/UICheckboxList.vue' /* webpackChunkName: "components/u-i-checkbox-list" */).then(c => wrapFunctional(c.default || c))
export const UIColorSelect = () => import('../../components/ui/UIColorSelect.vue' /* webpackChunkName: "components/u-i-color-select" */).then(c => wrapFunctional(c.default || c))
export const UIColorSelectItem = () => import('../../components/ui/UIColorSelectItem.vue' /* webpackChunkName: "components/u-i-color-select-item" */).then(c => wrapFunctional(c.default || c))
export const UIExpansion = () => import('../../components/ui/UIExpansion.vue' /* webpackChunkName: "components/u-i-expansion" */).then(c => wrapFunctional(c.default || c))
export const UIIcon = () => import('../../components/ui/UIIcon.vue' /* webpackChunkName: "components/u-i-icon" */).then(c => wrapFunctional(c.default || c))
export const UIIconInCircle = () => import('../../components/ui/UIIconInCircle.vue' /* webpackChunkName: "components/u-i-icon-in-circle" */).then(c => wrapFunctional(c.default || c))
export const UIImg = () => import('../../components/ui/UIImg.vue' /* webpackChunkName: "components/u-i-img" */).then(c => wrapFunctional(c.default || c))
export const UIInput = () => import('../../components/ui/UIInput.vue' /* webpackChunkName: "components/u-i-input" */).then(c => wrapFunctional(c.default || c))
export const UILightbox = () => import('../../components/ui/UILightbox.vue' /* webpackChunkName: "components/u-i-lightbox" */).then(c => wrapFunctional(c.default || c))
export const UILink = () => import('../../components/ui/UILink.vue' /* webpackChunkName: "components/u-i-link" */).then(c => wrapFunctional(c.default || c))
export const UIMarqueeText = () => import('../../components/ui/UIMarqueeText.vue' /* webpackChunkName: "components/u-i-marquee-text" */).then(c => wrapFunctional(c.default || c))
export const UIModal = () => import('../../components/ui/UIModal.vue' /* webpackChunkName: "components/u-i-modal" */).then(c => wrapFunctional(c.default || c))
export const UIModalContent = () => import('../../components/ui/UIModalContent.vue' /* webpackChunkName: "components/u-i-modal-content" */).then(c => wrapFunctional(c.default || c))
export const UIModalFooter = () => import('../../components/ui/UIModalFooter.vue' /* webpackChunkName: "components/u-i-modal-footer" */).then(c => wrapFunctional(c.default || c))
export const UIModalHeader = () => import('../../components/ui/UIModalHeader.vue' /* webpackChunkName: "components/u-i-modal-header" */).then(c => wrapFunctional(c.default || c))
export const UIPagination = () => import('../../components/ui/UIPagination.vue' /* webpackChunkName: "components/u-i-pagination" */).then(c => wrapFunctional(c.default || c))
export const UISelect = () => import('../../components/ui/UISelect.vue' /* webpackChunkName: "components/u-i-select" */).then(c => wrapFunctional(c.default || c))
export const UISkeletonElement = () => import('../../components/ui/UISkeletonElement.vue' /* webpackChunkName: "components/u-i-skeleton-element" */).then(c => wrapFunctional(c.default || c))
export const UISketchButton = () => import('../../components/ui/UISketchButton.vue' /* webpackChunkName: "components/u-i-sketch-button" */).then(c => wrapFunctional(c.default || c))
export const UITextarea = () => import('../../components/ui/UITextarea.vue' /* webpackChunkName: "components/u-i-textarea" */).then(c => wrapFunctional(c.default || c))
export const UIVideoPlay = () => import('../../components/ui/UIVideoPlay.vue' /* webpackChunkName: "components/u-i-video-play" */).then(c => wrapFunctional(c.default || c))
export const UiRecaptcha = () => import('../../components/ui/UiRecaptcha.vue' /* webpackChunkName: "components/ui-recaptcha" */).then(c => wrapFunctional(c.default || c))
export const AppBacklight = () => import('../../components/shared/AppBacklight.vue' /* webpackChunkName: "components/app-backlight" */).then(c => wrapFunctional(c.default || c))
export const AppBaseCard = () => import('../../components/shared/AppBaseCard.vue' /* webpackChunkName: "components/app-base-card" */).then(c => wrapFunctional(c.default || c))
export const AppBreadcrumbs = () => import('../../components/shared/AppBreadcrumbs.vue' /* webpackChunkName: "components/app-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AppCaption = () => import('../../components/shared/AppCaption.vue' /* webpackChunkName: "components/app-caption" */).then(c => wrapFunctional(c.default || c))
export const AppCircleProgress = () => import('../../components/shared/AppCircleProgress.vue' /* webpackChunkName: "components/app-circle-progress" */).then(c => wrapFunctional(c.default || c))
export const AppContainer = () => import('../../components/shared/AppContainer.vue' /* webpackChunkName: "components/app-container" */).then(c => wrapFunctional(c.default || c))
export const AppCookies = () => import('../../components/shared/AppCookies.vue' /* webpackChunkName: "components/app-cookies" */).then(c => wrapFunctional(c.default || c))
export const AppCounter = () => import('../../components/shared/AppCounter.vue' /* webpackChunkName: "components/app-counter" */).then(c => wrapFunctional(c.default || c))
export const AppLogo = () => import('../../components/shared/AppLogo.vue' /* webpackChunkName: "components/app-logo" */).then(c => wrapFunctional(c.default || c))
export const AppLogoWithBlinkingEyes = () => import('../../components/shared/AppLogoWithBlinkingEyes.vue' /* webpackChunkName: "components/app-logo-with-blinking-eyes" */).then(c => wrapFunctional(c.default || c))
export const AppMap = () => import('../../components/shared/AppMap.vue' /* webpackChunkName: "components/app-map" */).then(c => wrapFunctional(c.default || c))
export const AppPreloader = () => import('../../components/shared/AppPreloader.vue' /* webpackChunkName: "components/app-preloader" */).then(c => wrapFunctional(c.default || c))
export const AppSection = () => import('../../components/shared/AppSection.vue' /* webpackChunkName: "components/app-section" */).then(c => wrapFunctional(c.default || c))
export const AppSeoSection = () => import('../../components/shared/AppSeoSection.vue' /* webpackChunkName: "components/app-seo-section" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonBreadcrumbs = () => import('../../components/shared/AppSkeletonBreadcrumbs.vue' /* webpackChunkName: "components/app-skeleton-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonCaption = () => import('../../components/shared/AppSkeletonCaption.vue' /* webpackChunkName: "components/app-skeleton-caption" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonCard = () => import('../../components/shared/AppSkeletonCard.vue' /* webpackChunkName: "components/app-skeleton-card" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonCatalog = () => import('../../components/shared/AppSkeletonCatalog.vue' /* webpackChunkName: "components/app-skeleton-catalog" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonCertificates = () => import('../../components/shared/AppSkeletonCertificates.vue' /* webpackChunkName: "components/app-skeleton-certificates" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonDocumentsProductPage = () => import('../../components/shared/AppSkeletonDocumentsProductPage.vue' /* webpackChunkName: "components/app-skeleton-documents-product-page" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonForm = () => import('../../components/shared/AppSkeletonForm.vue' /* webpackChunkName: "components/app-skeleton-form" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonItemsSection = () => import('../../components/shared/AppSkeletonItemsSection.vue' /* webpackChunkName: "components/app-skeleton-items-section" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonMap = () => import('../../components/shared/AppSkeletonMap.vue' /* webpackChunkName: "components/app-skeleton-map" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonProductPage = () => import('../../components/shared/AppSkeletonProductPage.vue' /* webpackChunkName: "components/app-skeleton-product-page" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonSearch = () => import('../../components/shared/AppSkeletonSearch.vue' /* webpackChunkName: "components/app-skeleton-search" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonServiceCenters = () => import('../../components/shared/AppSkeletonServiceCenters.vue' /* webpackChunkName: "components/app-skeleton-service-centers" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonServiceCentersMobile = () => import('../../components/shared/AppSkeletonServiceCentersMobile.vue' /* webpackChunkName: "components/app-skeleton-service-centers-mobile" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonSliderCards = () => import('../../components/shared/AppSkeletonSliderCards.vue' /* webpackChunkName: "components/app-skeleton-slider-cards" */).then(c => wrapFunctional(c.default || c))
export const AppSkeletonWhereToBuy = () => import('../../components/shared/AppSkeletonWhereToBuy.vue' /* webpackChunkName: "components/app-skeleton-where-to-buy" */).then(c => wrapFunctional(c.default || c))
export const BackgroundLines = () => import('../../components/shared/BackgroundLines.vue' /* webpackChunkName: "components/background-lines" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/shared/BaseModal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const ImageBottomGradient = () => import('../../components/shared/ImageBottomGradient.vue' /* webpackChunkName: "components/image-bottom-gradient" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/shared/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const PageBlocks = () => import('../../components/shared/PageBlocks.vue' /* webpackChunkName: "components/page-blocks" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/shared/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProseBlock = () => import('../../components/shared/ProseBlock.vue' /* webpackChunkName: "components/prose-block" */).then(c => wrapFunctional(c.default || c))
export const SliderFraction = () => import('../../components/shared/SliderFraction.vue' /* webpackChunkName: "components/slider-fraction" */).then(c => wrapFunctional(c.default || c))
export const SocialButtons = () => import('../../components/shared/SocialButtons.vue' /* webpackChunkName: "components/social-buttons" */).then(c => wrapFunctional(c.default || c))
export const UsefulProductCard = () => import('../../components/shared/UsefulProductCard.vue' /* webpackChunkName: "components/useful-product-card" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
