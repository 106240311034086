// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import GmapVue from 'gmap-vue';

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyD3r_o1OgQyyyl4lJ0AKcUaJa8-ZLK__dU',
    libraries: 'places,visualization,drawing',
  },
  installComponents: true,
});
