
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    text: {
      type: String,
      default: 'Button',
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconPosition: {
      type: String,
      default: 'right',
    },
    variant: {
      type: String,
      default: 'gradientRed',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: String,
      default: 'min-w-[180px]',
    },
    badge: {
      type: Boolean,
      default: false,
    },
    isShowBadge: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    rel: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const variants = {
      outlined:
        'bg-transparent border border-gray-light group-hover:bg-white group-hover:text-dark',
      gradientRed:
        'bg-gradient-to-r from-red-light to-pink bg-[length:100%_100%] group-hover:bg-[length:1000%_100%] group-hover:bg-right',
      default: 'group-hover:text-pink',
    };

    const classes = computed(() => variants[props.variant]);
    const minWidthComputed = computed(() =>
      props.variant === 'default' || props.iconOnly ? '' : props.minWidth
    );

    const clickEvent = () => {
      emit('click');
    };

    return {
      classes,
      minWidthComputed,
      clickEvent,
    };
  },
});
