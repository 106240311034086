
import { defineComponent, useStore } from '@nuxtjs/composition-api';
import AppHeader from '~/domains/header/components/AppHeader';
import AppFooter from '~/domains/footer/components/AppFooter';

export default defineComponent({
  components: { AppFooter, AppHeader },
  middleware: 'checkDevice',
  head() {
    const baseURL = this.$baseURL;
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    const url =
      this.$route.path !== '/' ? `${baseURL}${this.$route.path}` : baseURL;
    const bodyClasses = ['theme-lrg'];
    if (this.$isGreece) bodyClasses.push('is-greece');
    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      bodyAttrs: {
        class: bodyClasses,
      },
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: url,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${baseURL}/images/lorgar-og.jpg`,
        },
        ...i18nHead.meta,
      ],
    };
  },
  setup() {
    const store = useStore();
    const menuMain = store.getters['menus/getMenuMain'];
    const menuFooter = store.getters['menus/getMenuFooter'];
    const footerCopyrightLinks = store.getters['menus/getFooterCopyrightLinks'];

    return {
      menuMain,
      menuFooter,
      footerCopyrightLinks,
    };
  },
});
