/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
export const state = () => ({
  config: {
    language: [
      {
        code: 'en',
        name: 'English',
      },
    ],
  },
});

export const getters = {
  getGTM(state) {
    return state.config.gtm;
  },

  getDefaultLanguage(state) {
    return state.config.language.find((lang) => lang.default);
  },

  getSocial(state) {
    return state?.config?.social_links?.map?.((social) => ({
      name: social.url.split('.')[1],
      src: social.url,
    }));
  },
};

export const mutations = {
  setConfig(state, config) {
    state.config = config;
  },
};

export const actions = {
  async load({ commit }, { $api: { siteInfoService } }) {
    try {
      const { data } = await siteInfoService.getSiteInfo();
      commit('setConfig', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
};
